.smalltext{
    font-size: 12px !important;
}

.bs-ip{
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.register:hover , register:active{
    background-image: linear-gradient(to right,#0D6EFD, #4caf50);
}

.lrbutton{
    width:200px;
}
.linkBlue{
    color:#0D6EFD;
}
.err{
    color:red;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;

}

@media (max-width: 770px) {
    .mycard{
        background: white;
        backdrop-filter: blur(5px);
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
        border-radius: 5px;
        padding:30px;
        width: 100%;
        margin:10px;
    }
  }

  @media (min-width: 770px) {
    .mycard{
        background: white;
        backdrop-filter: blur(5px);
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
        border-radius: 5px;
        padding:1px;
        min-width: 50%;
    }
  }