.breadcrumb{
    text-decoration: none;
}

    
    div.track, div.bar {
    box-sizing: border-box;
    position: relative;
    }
    
    div.progress {
    width: 100px;
    padding-left: 2px;
    padding-right: 2px;
    height: 30px;
    border-radius: 5px;
    border: solid 3px #4e4e4e;
    background-color: #1e1e1e;
    position: relative;
    top: calc(50% - 13px);
    left: calc(50% - 53px);
    }
    
    div.track {
    position: relative;
    width: 100%;
    height: 21px;
    overflow: hidden;
    -webkit-animation: prgBar 5s linear 0s infinite ;
    animation: prgBar 5s linear 0s infinite ;
    }
    
    div.bar {
    height: 18px;
    width: 6px;
    background-color: #3B71CA;
    position: relative;
    border-radius:2px;
    box-shadow: 0px 0px 5px #3B71CA;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 100px;
    top: 3px;
    float: left;
    clear: top;
    }
    
    @-webkit-keyframes prgBar {
    0% { width: 0% }
    9.99% { width: 0% }
    10% { width: 10% }
    95% { width: 100% }
    }
    
    @keyframes prgBar {
    0% { width: 0% }
    9.99% { width: 0% }
    10% { width: 10% }
    95% { width: 100% }
    }


    .circle {
        width: 120px;
        line-height: 120px;
        border-radius: 50%;
        text-align: center;
        font-size: 32px;
        border: 3px solid #000;
      }

      .row-no-padding > [class*="col-"] {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top:  0 !important;
        padding-bottom:  0 !important;
    }

  