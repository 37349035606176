.step {
    color: white;
    width: 20px;
    height: 20px;
    font-size: 12px;
    background-color: indigo;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .step.completed {
    background-color: indigo;
  }