.paginate{
    border : 2px solid rgba(109, 94, 94, 0.5);
    width: 80%;
    height:50px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
}

.pageNumber{
   
    cursor: pointer;
}

.activePage{
    color:green;
    border: 1px solid gray;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.disabledPage{
    color: gray;
}