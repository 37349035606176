.customcheck {
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .customcheck input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 6px;
    left: 0;
    height: 15px;
    width: 15px;
    /* background-color: #eee; */
    background-color: darkgray;
    border:1px solid rgba(0,0,0,.25);
    border-radius: 50%;
  }
  .customcheck:hover input ~ .checkmark {
    /* background-color: #ccc; */
    background-color: darkgray;
  }
  .customcheck input:checked ~ .checkmark {
    background-color: #0275d8;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .customcheck input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .customcheck .checkmark:after {
    left: 3px;
    top: 3px;
    text-align: center;
    width: 7px;
    height: 7px;
    /* border: solid white; */
    border-radius: 50%;
    background-color: white;
  }

/* True or False */
