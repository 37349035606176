.reportHeadings{
    /* background: rgb(35, 47, 62);
    color: white; */
    font-size: large;
    font-weight: 900;
    padding: 10px;
    border:2px solid black;
    cursor:pointer;
    transition: 0.5s;
}


.collapsehead:hover{
    background-color: yellowgreen;
}

.download{
    background-color: #f5f5f5;
    width: 210mm;
    min-height: 297mm;
    margin-left: auto;
    margin-right: auto;
}