/* 
html,
body{
  width:100%;
  overflow-x:hidden;
} */

.header_left_bar{
    width:5%;
    /* background-color: #4CAF50; */
    height:100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.header_left{
    width:15%;
    /* background-color: #4CAF50; */
    height:100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.header_center{
    display: flex;
    width: 100%;
    /* width:75% */
    justify-content: flex-end;
    text-transform: uppercase;
    /* letter-spacing: 0.1rem; */
    align-items: center;
    height: 100%;
}

.header_item{
    margin-left: 30px;
    margin-right: 30px;
}
.header_right{
    width: 10%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.header_contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: smaller;
}


.colorHr{
    display: block;
    background-color: #0D6EFD;
    /* margin-top: 20px;
    margin-bottom: 20px; */
    width: 100%;
    height: 2px;
    opacity: 1;
}


.linkLH{
    line-height: 2.5;
}

@media only screen and (max-width: 770px) {
    .sideBarMenu {
        font-weight:600;
        font-size:18px;
        margin-top:20px;
        margin-bottom:20px;
        background-color:black;
        height:40px;
        color:white
    }
    .sideBar{
        position:fixed;
        background-color:black;
        height:100vh;
        top:0;
        left:-1000px;
        z-index:50000;
        width:60%;
        padding: 20px;
    }
  }

  @media only screen and (min-width: 770px) {
    .sideBarMenu {
        font-weight:600;
        font-size:22px;
        margin-top:20px;
        margin-bottom:20px;
        background-color:black;
        height:40px;
        color:white;
        
    }
    .sideBar{
        position:fixed;
        background-color:black;
        height:100vh;
        top:10px;
        left:-1000px;
        z-index:50000;
        width:20%;
        padding-left: 20px;
        padding-right: 20px;
        opacity: 1;
    }
  }

  .normalHeader{
    text-decoration: none;
    color:white;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    padding: 10px;
    border-bottom: 5px solid transparent;
    display: block;
}

.normalHeader:hover{
    color:#232F3E;
    background-color: #4CAF50;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 5px solid #4CAF50;
    border-bottom: 5px solid transparent;
}


.activeHeader{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color:white;
    text-decoration: none;
    border-bottom: 5px solid #4CAF50;
    height: 100%;
    padding: 10px;
    background-image: linear-gradient(to bottom,#4CAF50,#232F3E,#232F3E,#232F3E);
}

.activeHeaderDemo{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color:white;
    text-decoration: none;
    border-bottom: 5px solid #4CAF50;
    height: 100%;
    padding: 10px;
    background-image: linear-gradient(to bottom,#4CAF50,#232F3E);
}
.activeHeader:hover{
    color:white;
    background-color: #4CAF50;
    height: 100%;
    padding: 10px;
    
}
.normalHeader:hover > .selectedHeaderOptions1{
    visibility: visible;
    
}
.activeHeader:hover  > .selectedHeaderOptions1{
    visibility: visible;
}
.selectedHeaderOptions1{
    position: absolute;
    z-index: 20000;
    padding:16px;
    visibility: hidden;
    background-color: #4CAF50;
    line-height: 2;
    font-size: 12px;;
    top:7vh;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    margin-left: -10px;
}
  


.subHeader{
    color: black;
}
.subHeader:hover{
    color: white;
}

.subHeaderLink{
    text-decoration: none;
    color: black;
}

.subHeaderLink:hover{
    text-decoration: none;
    color: white;
}

.noLink{
    text-decoration: none;
    color:white;
}

.customLink{
    text-decoration: none;
    color:white;
}

.customLink:hover{
    text-decoration: none;
    color:#0D6EFD;
}