.topic{
    border: 2px solid gainsboro;
}

.subtopic{
    margin-left: 10px;
    border: 2px solid bisque;
    background-color: aliceblue;
}

.headingFont{
    font-weight: 600;
}

.detailedReportButton{
    background-color: lightblue !important;
    cursor: pointer;
    margin: 10px;
    width: 300px;
}

.detailedReportButton:hover{
    background-color: #4CAF50 !important;
    cursor: pointer;
    margin: 10px;
    width: 300px;
}

.AnswersReport{
    background-color: gainsboro;
    padding: 10px;
}

.reportHeading{
    font-size: large;
    font-weight: 900;
    padding: 10px;
    cursor:pointer;
    transition: 0.5s;
}