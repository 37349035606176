
.dragelement{
  border: 2px solid black;
  padding: 5px;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top:10px ;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
}

.dragelement:hover{
  font-weight: 600 !important;
}
.dragelementchooseouter{
  border: 2px solid black;
  padding: 5px;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top:10px ;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
}
.dragelementright{
  font-weight: normal;
  display: inline-block;
  padding: 3px;
  flex-wrap: wrap;
  border-radius: 5px;
  
}

.dragelementright:hover{
  font-weight: 600 !important;
}

.dragelementnoborder{

}

.dragelementnoborder:hover{
  font-weight: 600 !important;
}

.dropelement{
  border: 2px dotted black;
  padding: 5px;
  text-align: center;
  min-width: 200px;
  min-height: 25px;
  font-style: italic;
  font-weight: 600;
}

.dropelementrearrange{
  border-bottom: 2px dotted black;
  text-align: center;
  /* padding: 5px; */
  min-width: 100px;
  min-height: 27px;
  font-style: italic;
  font-weight: 600;
}

.dropelementrearrangebw{
  border-bottom: 2px dotted black;
  display: flex;
  flex-direction: row;
  padding: 5px;
  min-width: 700px;
  min-height: 25px;
  font-style: italic;
  font-weight: 600;
}

.mr-10{
   margin-right: 10px;
}
.dragelementchoose{
   padding: 4px;
   text-align: center;
   cursor: pointer;
}
.dragelementchoose:hover{
  font-weight: 600 !important;
}
.dropelementdragin{
  border-bottom: 2px dotted black;
  text-align: center;
  min-width: 100px;
  display: inline-block;
  /* padding: 5px; */
  /* min-height: 30px; */
  font-style: italic;
  font-weight: 600; 
}

.typein{
  /* min-width: 100px; */
  border:none;
  border-bottom:2px dotted black;
  font-style:italic; 
  font-weight: 600;
  text-indent: 5px;
  outline: none;
  padding: 0px;
  vertical-align: baseline;
  margin: 0px;
  line-height: normal;
  /* height: 30px; */
}

.fillin{
  /* min-width:15%;
  border:none;
  text-indent: 5px;
  border-bottom:2px dotted black;
  outline:none;
  font-style:italic; 
  font-weight: 600;
  border-collapse: collapse;
  border-spacing: 0;
  height: 17px;
  padding-bottom: 5px; */
  min-width: 100px;
  border:none;
  border-bottom:2px dotted black;
  outline:none;
  font-style:italic; 
  font-weight: 600;
  height: 20px;
  text-indent: 5px;
  padding: 5px;
}

 .mydropdown{
   border:none;
   border-bottom:2px dotted black;
   display:inline;
   min-width:150px;
   font-style: italic;
   cursor: pointer;  
   overflow: hidden; 
   background-color: lightgray;
   padding: 2px;
   text-indent: 2px;
   font-weight: 600;
}
.mydropdown:hover , .myoption:active , .myoption:checked{
  border:2px dotted #0D6EFD !important;
}
.myoption{
  font-style: normal;
  background-color: white;
}
.mydropdown .myoption:checked,
.mydropdown .myoption:hover ,
.mydropdown .myoption:active {
    background-color: lightgray;
    font-weight: 600;
} 

.choose{
   font-style:normal;
   
}

/* @media (hover: hover) and (pointer: fine) { */
.choose:hover{
   font-weight:600;
   background-color: lightgray;
}
/* }  */

.choose input[type="radio"]:checked+label,input[type="checkbox"]:checked+label {
  font-style: italic;
  font-weight: 600;
}
.choose:checked{
  font-style: italic;
  font-weight: 600;
}

.cp{
  margin-left: 3px;
  cursor: pointer;
}



.tooltipdelete{
  color: black;
}

.tooltipdelete:hover{
  color: red;
}

.mytooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
  z-index: 0;
}

.mytooltip .tooltiptext {
  visibility: hidden;
 
  background-color: lightgray;
  color: #fff;
  color: black;
  font-size: 12px;
  text-align: center;
  border-radius: 6px;
  padding: 2px 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -12px;
  left: 105%;
}

.mytooltip:hover .tooltiptext {
  visibility: visible;
}

/* .form-check-input{
  background-color: darkgray !important;
}

.form-check-input:checked ,.form-check-input:focus,.form-check-input:active {
  background-color: #0275d8 !important;
  outline: none !important;
  box-shadow: 0 0 0 0 rgba(0,123,255,0) !important;
  -webkit-box-shadow: none !important;
} */


.dndcopy ~ div {
  display: none !important;
}

.selected{
  background-color: lightgray;
}

.choosed{
  background-color: darkgray;
}

.borderdraginright{
  border:'2px solid black';
}



img{
  max-width:100% !important;
  max-height: 300px !important;
}

.bgred{
  background: #ff704d;
  opacity: 1 !important;
}

.bggreen{
  background: #66ff66;
  opacity: 1 !important;
}

.bgwhite{
  background: white;
}

.bggray{
  background: gainsboro;
}