.seesaw {
    animation: seesaw_animate 5s infinite;
  }

@keyframes seesaw_animate {
    0% {
        transform: rotate(5deg);
    }

    25% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(-5deg);
    }

    75% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(5deg);
    }
}